import { ethers } from 'ethers';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { abi, config } from '../config';
import { useEthAccount } from './wallet.context';

const zero = ethers.BigNumber.from(0);

interface IContractContext {
  state: number;
  totalSupply: ethers.BigNumber;
  maxSupply: ethers.BigNumber;
  maxMintAmountPerTx: ethers.BigNumber;
  cost: ethers.BigNumber;
  numberMinted: ethers.BigNumber;
  contract: ethers.Contract | null;
  refetch: () => Promise<void>;
  mintedAllowlist: ethers.BigNumber;
}

const defaultState: IContractContext = {
  state: 0,
  totalSupply: zero,
  maxSupply: zero,
  maxMintAmountPerTx: zero,
  numberMinted: zero,
  cost: zero,
  contract: null,
  mintedAllowlist: zero,
  refetch: () => Promise.resolve(),
};

const contractContext = createContext<IContractContext>(defaultState);

export const useContractContext = () => useContext(contractContext);

const ContractContextProvider = ({ children }: PropsWithChildren) => {
  const { account } = useEthAccount();

  const [state, setstate] = useState(defaultState.state);
  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const [totalSupply, setTotalSupply] = useState(defaultState.totalSupply);
  const [maxSupply, setMaxSupply] = useState(defaultState.maxSupply);
  const [maxMintAmountPerTx, setMaxMintAmountPerTx] = useState(
    defaultState.maxMintAmountPerTx,
  );
  const [numberMinted, setNumberMinted] = useState(defaultState.numberMinted);
  const [mintedAllowlist, setMintedAllowlist] = useState(
    defaultState.numberMinted,
  );
  const [cost, setCost] = useState(defaultState.cost);

  useEffect(() => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const cntrct = new ethers.Contract(config.contractAddress, abi, provider);
      setContract(cntrct);
    }
  }, []);

  const fetch = useCallback(async () => {
    const address = await account?.getAddress();
    if (contract && address) {
      const [
        stateC,
        totalSupplyC,
        maxSupplyC,
        maxMintAmountPerTxC,
        numberMintedC,
        costC,
        mintedAllowlistC,
      ] = await Promise.all([
        contract.functions.state(),
        contract.functions.totalSupply(),
        contract.functions.maxSupply(),
        contract.functions.maxMintAmountPerTx(),
        contract.functions.numberMinted(address),
        contract.functions.cost(),
        contract.functions.mintedAllowlist(address),
      ]);

      setstate(stateC[0]);
      setTotalSupply(totalSupplyC[0]);
      setMaxSupply(maxSupplyC[0]);
      setMaxMintAmountPerTx(maxMintAmountPerTxC[0]);
      setNumberMinted(numberMintedC[0]);
      setCost(costC[0]);
      setMintedAllowlist(mintedAllowlistC[0]);
    }
  }, [contract, account]);

  useEffect(() => {
    fetch();
  }, [contract, account?._address]);

  const value: IContractContext = useMemo(
    () => ({
      totalSupply,
      cost,
      maxSupply,
      maxMintAmountPerTx,
      numberMinted,
      contract,
      state,
      refetch: fetch,
      mintedAllowlist,
    }),
    [
      state,
      totalSupply,
      maxSupply,
      maxMintAmountPerTx,
      numberMinted,
      contract,
      fetch,
      cost,
      mintedAllowlist,
    ],
  );

  return (
    <contractContext.Provider value={value}>
      {children}
    </contractContext.Provider>
  );
};

export default ContractContextProvider;
