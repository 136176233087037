import { useState } from 'react';
import MintCountWithButton from '../components/MintCountWithButton';
import MintStatus from '../components/MintStatus';
import Page from '../components/Page';
import TotalMintedCounter from '../components/TotalMintedCounter';

const Paused = () => {
  const [count, setCount] = useState(0);

  return (
    <Page>
      <MintStatus text="Mint Paused" />
      <TotalMintedCounter />
      <MintCountWithButton disabled max={0} count={count} setCount={setCount} />
    </Page>
  );
};

export default Paused;
