import { ThemeProvider } from '@mui/material';
import ContractContextProvider from './contexts/contract.context';
import EthAccountContextProvider from './contexts/wallet.context';
import WhitelistContextProvider from './contexts/whitelist.context';
import Pages from './pages';
import theme from './theme';

const App = () => (
  <ThemeProvider theme={theme}>
    <EthAccountContextProvider>
      <ContractContextProvider>
        <WhitelistContextProvider>
          <Pages />
        </WhitelistContextProvider>
      </ContractContextProvider>
    </EthAccountContextProvider>
  </ThemeProvider>
);

export default App;
