import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import MintCountWithButton from '../components/MintCountWithButton';
import MintingStatus from '../components/MintingStatus';
import Page from '../components/Page';
import { config } from '../config';
import { useContractContext } from '../contexts/contract.context';
import { useEthAccount } from '../contexts/wallet.context';

const gif = require('../images/paid.gif');

const Public = () => {
  const { account } = useEthAccount();
  const {
    maxMintAmountPerTx,
    contract,
    cost,
    refetch,
    numberMinted,
    totalSupply,
  } = useContractContext();
  const max = useMemo(
    () => maxMintAmountPerTx.toNumber(),
    [maxMintAmountPerTx],
  );
  const [count, setCount] = useState(max);
  const [mintingStatus, setMintingStatus] = useState('');

  const onMintClick = async () => {
    if (contract && account) {
      const contractWithSigner = contract.connect(account);
      const result = await contractWithSigner.functions.mintMega({
        value: cost.mul(count),
        gasLimit: config.gasLimit,
      });
      setMintingStatus(`Minting your ${config.tokenNamePlural}...`);
      await result.wait();
      setMintingStatus(`Successfully minted your ${config.tokenNamePlural}`);
      refetch();
    }
  };

  useEffect(() => {
    setCount(max);
  }, [max]);

  return (
    <Page>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={gif} alt="Mega" height={300} />
      </Box>
      <MintingStatus status={mintingStatus} />
      <MintCountWithButton
        onMintClick={onMintClick}
        max={max}
        count={1}
        setCount={setCount}
        disabled={numberMinted.eq(1) || totalSupply.eq(444)}
        paid
      />
    </Page>
  );
};

export default Public;
