import { Button } from '@mui/material';
import { useState } from 'react';
import MintingStatus from '../components/MintingStatus';
import Page from '../components/Page';
import TotalMintedCounter from '../components/TotalMintedCounter';
import { config } from '../config';
import { useContractContext } from '../contexts/contract.context';
import { useEthAccount } from '../contexts/wallet.context';
import { useWhitelistContext } from '../contexts/whitelist.context';

const gif = require('../images/free.gif');

const Allowlist = () => {
  const { contract, refetch, mintedAllowlist, totalSupply, maxSupply } =
    useContractContext();
  const { account } = useEthAccount();
  const { proof, allowance } = useWhitelistContext();
  const [mintingStatus, setMintingStatus] = useState('');

  const onMintClick = async () => {
    if (contract && account) {
      const contractWithSigner = contract.connect(account);
      const result = await contractWithSigner.functions.mintAllowList(
        1,
        allowance,
        proof,
        {
          value: 0,
          gasLimit: config.gasLimit,
        },
      );
      setMintingStatus(`Minting your ${config.tokenNamePlural}...`);
      await result.wait();
      setMintingStatus(`Successfully minted your ${config.tokenNamePlural}`);
      refetch();
    }
  };

  return (
    <Page>
      <img src={gif} alt="Free" height={300} />
      <MintingStatus status={mintingStatus} />
      <TotalMintedCounter />
      <Button
        onClick={onMintClick}
        disabled={mintedAllowlist.eq(allowance) || totalSupply.eq(maxSupply)}
      >
        Mint
      </Button>
    </Page>
  );
};

export default Allowlist;
