import { useContractContext } from '../contexts/contract.context';
import { useEthAccount } from '../contexts/wallet.context';
import Allowlist from './Allowlist';
import Paused from './Paused';
import Mega from './Mega';
import Soldout from './SoldOut';
import Unconnected from './Unconnected';
import Public from './Public';

const Pages = () => {
  const { account } = useEthAccount();
  const { state, totalSupply, maxSupply } = useContractContext();

  if (!account) {
    return <Unconnected />;
  }

  if (maxSupply.eq(0)) {
    return null;
  }

  if (state !== 3 && totalSupply.eq(maxSupply)) {
    return <Soldout />;
  }

  switch (state) {
    case 0:
      return <Paused />;
    case 1:
      return <Mega />;
    case 2:
      return <Allowlist />;
    case 3:
      return <Public />;
    default:
      return null;
  }
};

export default Pages;
