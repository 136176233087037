import MintStatus from '../components/MintStatus';
import Page from '../components/Page';

const Soldout = () => (
  <Page>
    <MintStatus text="Sold out" />
  </Page>
);

export default Soldout;
