import Page from '../components/Page';
import Heading from '../images/heading.png';
import Camp from '../images/camp.png';

const Unconnected = () => (
  <Page>
    <img src={Heading} alt="Heading" style={{ marginTop: '100px' }} />
    <img src={Camp} alt="Camp" style={{ marginTop: '100px' }} />
  </Page>
);

export default Unconnected;
