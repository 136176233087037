import { Typography } from '@mui/material';
import { useContractContext } from '../contexts/contract.context';

const TotalMintedCounter = () => {
  const { totalSupply, maxSupply } = useContractContext();

  return (
    <Typography fontSize={50}>
      {totalSupply.toNumber()}
      {' '}
      /
      {' '}
      {maxSupply.toNumber()}
    </Typography>
  );
};

export default TotalMintedCounter;
