import { Typography } from '@mui/material';

interface IProps {
  status: string;
}

const MintingStatus = ({ status }: IProps) =>
  status ? (
    <Typography fontSize={40} fontFamily="'Russo One', sans-serif">
      {status}
    </Typography>
  ) : null;

export default MintingStatus;
