import { Button, Typography, Box } from '@mui/material';
import React from 'react';

interface IProps {
  disabled?: boolean;
  max: number;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  onMintClick?: () => void;
  paid?: boolean;
}

const MintCountWithButton = ({
  disabled = false,
  max,
  count,
  setCount,
  paid = false,
  onMintClick = () => {},
}: IProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Button disabled={disabled || count === 0} onClick={() => onMintClick()}>
      Mint {paid ? '0.04 Eth' : ''}
    </Button>
  </Box>
);

export default MintCountWithButton;
