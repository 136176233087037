import {
  createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { config } from '../config';
import { useEthAccount } from './wallet.context';

interface IWhitelistContext {
  proof: string[];
  allowance: number;
}

const defaultState: IWhitelistContext = {
  proof: [],
  allowance: 0,
};

const whitelistContext = createContext<IWhitelistContext>(defaultState);

export const useWhitelistContext = () => useContext(whitelistContext);

const WhitelistContextProvider = ({ children }: PropsWithChildren) => {
  const { account } = useEthAccount();
  const [proof, setProof] = useState([]);
  const [allowance, setAllowance] = useState(0);

  const fetchMerkleInfo = useCallback(async () => {
    const address = await account?.getAddress();
    const res = await fetch(`${config.merkleUrl}/${address}`);
    const data = await res.json();
    const { proof: p, allowance: a } = data;

    setAllowance(a);
    setProof(p);
  }, [account]);

  useEffect(() => {
    fetchMerkleInfo();
  }, [fetchMerkleInfo]);

  const value = useMemo(() => ({ proof, allowance }), [proof, allowance]);

  return (
    <whitelistContext.Provider value={value}>
      {children}
    </whitelistContext.Provider>
  );
};

export default WhitelistContextProvider;
