import { ethers } from 'ethers';
import React, {
  PropsWithChildren, useCallback, useContext, useMemo, useState,
} from 'react';
import { config } from '../config';

const EthAccountContext = React.createContext<{
  account: null | ethers.providers.JsonRpcSigner;
  connect:() => Promise<void>;
}>({
      account: null,
      connect: () => Promise.resolve(),
    });

export const useEthAccount = () => useContext(EthAccountContext);

const EthAccountContextProvider = ({ children }: PropsWithChildren) => {
  const [account, setAccount] = useState<ethers.providers.JsonRpcSigner | null>(null);

  const connect = useCallback(async () => {
    const { ethereum } = window;

    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;

    if (metamaskIsInstalled && window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        await provider.send('eth_requestAccounts', []);
        const { chainId } = await provider.getNetwork();
        const signer = provider.getSigner();

        if (config.chainId !== chainId) {
          window.alert('Change network to Ethereum'); // eslint-disable-line no-alert
        } else if (signer) {
          setAccount(signer);
          provider.on('network', (_newNetwork, oldNetwork) => {
            if (oldNetwork) {
              window.location.reload();
            }
          });
        } else {
          window.alert('Something went wrong'); // eslint-disable-line no-alert
        }
      } catch (err) {
        window.alert('Something went wrong'); // eslint-disable-line no-alert
      }
    } else {
      window.alert('Install metamask'); // eslint-disable-line no-alert
    }
  }, []);

  const value = useMemo(() => ({ account, connect }), [account, connect]);

  return (
    <EthAccountContext.Provider value={value}>
      {children}
    </EthAccountContext.Provider>
  );
};

export default EthAccountContextProvider;
