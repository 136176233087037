import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import MintCountWithButton from '../components/MintCountWithButton';
import MintingStatus from '../components/MintingStatus';
import Page from '../components/Page';
import { config } from '../config';
import { useContractContext } from '../contexts/contract.context';
import { useEthAccount } from '../contexts/wallet.context';

const gif = require('../images/free.gif');

const Public = () => {
  const { account } = useEthAccount();
  const { maxMintAmountPerTx, contract, refetch, numberMinted } =
    useContractContext();
  const max = useMemo(
    () => maxMintAmountPerTx.sub(numberMinted).toNumber(),
    [maxMintAmountPerTx, numberMinted],
  );
  const [count, setCount] = useState(max);
  const [mintingStatus, setMintingStatus] = useState('');

  const onMintClick = async () => {
    if (contract && account) {
      const contractWithSigner = contract.connect(account);
      const result = await contractWithSigner.functions.mint({
        value: 0,
        gasLimit: config.gasLimit,
      });
      setMintingStatus(`Minting your ${config.tokenNamePlural}...`);
      await result.wait();
      setMintingStatus(`Successfully minted your ${config.tokenNamePlural}`);
      refetch();
    }
  };

  useEffect(() => {
    setCount(max);
  }, [max]);

  return (
    <Page>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={gif} alt="Free img" height={300} />
      </Box>
      <MintingStatus status={mintingStatus} />
      <MintCountWithButton
        onMintClick={onMintClick}
        max={max}
        count={count}
        setCount={setCount}
      />
    </Page>
  );
};

export default Public;
