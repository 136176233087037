import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff5f0f',
      contrastText: '#1cabd8',
    },
  },
  typography: {
    fontFamily: `'Russo One', sans-serif`,
    h1: {
      color: '#ffffff',
    },
    h6: {
      color: '#ffffff',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        sx: {
          color: '#ffffff',
          fontFamily: `'Russo One', sans-serif`,
          height: 50,
          width: 200,
          fontSize: '16px',
          borderRadius: 2,
          boxShadow: '4px 4px 0px rgba(51, 51, 51, 0.5)',
        },
      },
    },
  },
});

export default theme;

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}
