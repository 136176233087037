import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useEthAccount } from '../contexts/wallet.context';

const ConnectButton = () => {
  const [address, setAddress] = useState('');
  const { connect, account } = useEthAccount();

  useEffect(() => {
    account?.getAddress().then((address_) => {
      setAddress(address_);
    });
  }, [account]);

  return (
    account ? (
      <Button onClick={() => {}}>
        {address.slice(0, 10)}
        ...
      </Button>
    )
      : <Button onClick={connect}>Connect Wallet</Button>
  );
};

export default ConnectButton;
