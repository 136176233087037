import { Box, Typography, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import ConnectButton from './ConnectButton';
import Logo from '../images/logo.png';
import Clouds from '../images/clouds.png';

const Page = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary.contrastText,
      }}
    >
      <img
        src={Clouds}
        style={{
          width: '100%',
          position: 'absolute',
        }}
        alt="Clouds"
      />
      <Box
        sx={{
          height: '100px',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Box sx={{ flex: 1 }} />
        <Box
          sx={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <img src={Logo} alt="Logo" />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            paddingRight: '50px',
          }}
        >
          <ConnectButton />
        </Box>
      </Box>
      {children}
      <Box sx={{ height: '20vh', display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h6" fontFamily="'Russo One', sans-serif">
          © 2023 Mountaineers Inc. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Page;
